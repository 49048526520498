import Header from '@/header';
import Footer from '@/footer';
import React, { useEffect, useState } from 'react';
import { Button, Layout, List } from 'antd';
import { useNavigate } from 'react-router-dom';
import { NotionRenderer } from 'react-notion';
import axios from 'axios';
import { StreamingGuideData } from '@/pages/streaming-guide/data';

import 'react-notion/src/styles.css';
// import 'prismjs/themes/prism-tomorrow.css';
import '@/styles/notion.css';
import { useMediaQuery } from 'react-responsive';

const StreamingGuide = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  const [response, setResponse] = useState({});

  const [platforms, setPlatforms] = useState(StreamingGuideData);

  useEffect(() => {
    onSelect('e947ce4120a94468bd4bc890847d3835', 'melon');
  }, []);

  const onSelect = async (notionId: string, id: string) => {
    const response = await axios.get(`https://notion-api.splitbee.io/v1/page/${notionId}`, {
      headers: {
        Pragma: 'no-cache',
      },
    });
    if (!Object.keys(response.data).length) {
      navigate('/'); // 홈으로 리다이렉트
    } else {
      const temp = platforms.map((x) => {
        x.select = x.id === id;
        return x;
      });
      setPlatforms(temp);
      setResponse(response.data);
    }
  };

  return (
    <Layout className={'container'}>
      <Header isNotice={false} />
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginTop: isMobile ? 20 : 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <div style={{ fontWeight: '700', color: '#000', fontSize: isMobile ? 14 : 20 }}>
            스트리밍 가이드
          </div>
          <img
            src={require('@/assets/images/guide/streamingGuide.png')}
            alt="streamingGuide"
            style={{
              marginTop: 20,
              objectFit: 'contain',
              width: isMobile ? '100%' : '600px',
              height: isMobile ? 'auto' : '80px',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            gap: '16px',
            flexWrap: 'wrap',
            marginTop: 20,
            padding: '0 20px',
            width:'70%'
          }}
        >
          {platforms.map((platform) => (
            <Button
              onClick={() => onSelect(platform.notionId, platform.id)}
              key={platform.name}
              size={'large'}
              style={{
                borderWidth: 1,
                borderColor: '#fff',
                borderStyle: 'solid', // 테두리 스타일 설정
                borderRadius: '15px',
                backgroundColor: platform.select ? '#282828' : '#fff',
                color: platform.select ? '#fff' : '#282828',
                fontSize: '14px',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <img
                src={platform.icon}
                alt={platform.name}
                style={{ width: '24px', height: '24px', borderRadius: '50%' }} // 이미지 크기 조정
              />
              {platform.name}
            </Button>
          ))}
        </div>

        {Object.keys(response).length ? (
          <div style={{ width: '100%' }}>
            <NotionRenderer hideHeader={true} blockMap={response} fullPage={true} />
          </div>
        ) : null}
      </div>
      <Footer />
    </Layout>
  );
};
export default StreamingGuide;
