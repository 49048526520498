import Header from '@/header';
import { Avatar, Button, Card, Layout } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Footer from '@/footer';
import { requestFetchOneClickMelonShare } from '@/api/oneClick';
import { useMutation } from '@tanstack/react-query';
import { requestFetchMVCountingList } from '@/api/mvCounting';
import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';

const MvCounting = () => {
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  const [mvData, setMvData] = useState<
    | {
        title: string;
        view_cnt: number;
        views_increase_last_hour: number;
        thumbnail: string;
        big_thumbnail: string;
        video_id: string;
      }[]
    | []
  >([]);

  const [mvTitle, setMvTitle] = useState<
    | {
        title: string;
        view_cnt: number;
        views_increase_last_hour: number;
        thumbnail: string;
        big_thumbnail: string;
        video_id: string;
      }[]
    | []
  >([]);

  const { mutate: youtubeMutate } = useMutation(requestFetchMVCountingList, {
    onSuccess: (data) => {
      if (data.length > 0) {
        // Set the first item to mvTitle
        setMvTitle([data[0]]);
        // Set the remaining items to mvData
        setMvData(data.slice(1));
      } else {
        // If no data, reset both states to empty arrays
        setMvTitle([]);
        setMvData([]);
      }
    },
    onError: (e) => {
      console.log('eee', e);
    },
  });

  useEffect(() => {
    youtubeMutate();
  }, []);

  const onMoveYoutube = (idx: string) => {
    return window.open(`https://www.youtube.com/watch?v=${idx}`);
  };

  return (
    <Layout className={'container'}>
      <Header isNotice={false} />
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginTop: isMobile ? 20 : 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <div style={{ fontWeight: '700', color: '#000', fontSize: isMobile ? 14 : 20 }}>뮤직비디오 n억뷰 카운팅</div>

          <img
            src={require('@/assets/images/guide/mvCounting.png')}
            alt="mvCounting"
            style={{
              objectFit: 'contain',
              width: isMobile ? '100%' : '491px',
              height: isMobile ? 'auto' : '100px',
            }}
          />
        </div>

        <div
          style={{
            width: isMobile ? '100%' : '800px',
            marginTop: 10,
            paddingLeft: isMobile ? 10 : 0,
            paddingRight: isMobile ? 10 : 0,
          }}
        >
          <div
            style={{
              width: '100%',
              borderRadius: '10px',
              background: '#fff',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: isMobile ? 10 : 20,
              paddingBottom: isMobile ? 10 : 20,
              paddingLeft: isMobile ? 10 : 28,
              paddingRight: isMobile ? 10 : 28,
              flexDirection: 'row',
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  flex: 0.4,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  marginTop: 10,
                }}
              >
                <div style={{ color: '#000', fontSize: isMobile ? '15px' : 33, fontWeight: 'bold' }}>
                  {mvTitle?.[0]?.title}
                </div>
                <div style={{ color: '#000', fontSize: isMobile ? '13px' : 24, marginTop: 12, fontWeight: '500' }}>
                  Views
                </div>
                <div style={{ color: '#000', marginTop: 4, fontSize: isMobile ? '12px' : 20 }}>
                  {mvTitle?.[0]?.view_cnt.toLocaleString()}
                </div>
                <div style={{ color: '#000', marginTop: 4, fontSize: isMobile ? '12px' : 20 }}>
                  <ArrowUpOutlined /> {mvTitle?.[0]?.views_increase_last_hour.toLocaleString()} / 1H
                </div>
              </div>
              <div style={{ flex: 0.6, marginLeft: 10 }}>
                <img
                  onClick={() => onMoveYoutube(mvTitle?.[0]?.video_id)}
                  src={mvTitle?.[0]?.big_thumbnail ?? mvTitle?.[0]?.thumbnail}
                  alt="big_thumbnail"
                  style={{
                    cursor: 'pointer',
                    objectFit: 'contain',
                    width: '100%',
                    height: 'auto',
                    borderRadius: 20,
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ fontSize: 15, color: '#5B5B5B', marginTop: 33 }}>N억뷰에 가까운 순</div>
          <List
            itemLayout="horizontal"
            dataSource={mvData}
            renderItem={(item, index) => (
              <List.Item style={{ border: 'none' }}>
                <div
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                    background: '#fff',
                    padding: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    {isMobile ? (
                      <>
                        <div>
                          <img
                            src={item.thumbnail}
                            alt="mvCounting"
                            style={{
                              objectFit: 'cover',
                              width: '60px',
                              height: '60px',
                              background: 'blue',
                            }}
                          />
                          <div
                            onClick={() => onMoveYoutube(item.video_id)}
                            style={{
                              cursor: 'pointer',
                              background: '#BDB4FD',
                              paddingLeft: 12,
                              paddingRight: 11,
                              paddingTop: '2px',
                              paddingBottom: '2px',
                              borderRadius: 20,
                            }}
                          >
                            <div style={{ color: '#fff', fontSize: '13px' }}>MV ON</div>
                          </div>
                        </div>
                        <div style={{ flex: 1, marginLeft: 10, paddingLeft: 5, paddingRight: 5 }}>
                          <div style={{ color: '#000', fontSize: '14px', fontWeight: 'bold' }}>{item.title}</div>

                          <div
                            style={{
                              marginTop: 17,
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                fontSize: '11px',
                                color: '#000',
                              }}
                            >
                              {item.view_cnt.toLocaleString()}
                            </div>
                            <div style={{ color: '#000', fontSize: '11px' }}>
                              <ArrowUpOutlined /> {item.views_increase_last_hour.toLocaleString()} / 1H
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={item.thumbnail}
                          alt="mvCounting"
                          style={{
                            objectFit: 'contain',
                            width: '60px',
                            height: '60px',
                          }}
                        />
                        <div style={{ flex: 1, marginLeft: isMobile ? 10 : 32 }}>
                          <div style={{ color: '#000', fontSize: '13', fontWeight: 'bold' }}>{item.title}</div>
                        </div>
                        <div
                          style={{
                            flex: 0.6,
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '13px',
                              color: '#000',
                            }}
                          >
                            {item.view_cnt.toLocaleString()}
                          </div>
                          <div style={{ color: '#000', fontSize: '13px' }}>
                            <ArrowUpOutlined /> {item.views_increase_last_hour.toLocaleString()} / 1H
                          </div>
                        </div>
                        <div
                          onClick={() => onMoveYoutube(item.video_id)}
                          style={{
                            marginLeft: 20,
                            cursor: 'pointer',
                            background: '#BDB4FD',
                            paddingLeft: 12,
                            paddingRight: 11,
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: 20,
                          }}
                        >
                          <div style={{ color: '#fff', fontSize: '13px' }}>MV ON</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default MvCounting;
