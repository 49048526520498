export const OneClickData = [
  {
    idx: 1,
    img: require('@/assets/images/oneClick/bugsCard.png'),
  },
  {
    idx: 2,
    img: require('@/assets/images/oneClick/genieCard.png'),
  },
  {
    idx: 3,
    img: require('@/assets/images/oneClick/melonCard.png'),
  },
  {
    idx: 4,
    img: require('@/assets/images/oneClick/floCard.png'),
  },
  {
    idx: 5,
    img: require('@/assets/images/oneClick/vibeCard.png'),
  },
  {
    idx: 6,
    img: require('@/assets/images/oneClick/spotifyCard.png'),
  },
  {
    idx: 7,
    img: require('@/assets/images/oneClick/youtubeCard.png'),
  },
  {
    idx: 8,
    img: require('@/assets/images/oneClick/appleCard.png'),
  },
];
