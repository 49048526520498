import { Button, Modal } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import useDeviceType from '@/utils/hooks/useDeviceType';
import { useMutation } from '@tanstack/react-query';
import { requestFetchOneClickMelon } from '@/api/oneClick';
import { MobileHomeMelonCashData } from '@/components/home/data';
import { requestFetchMelonCashList } from '@/api/melonCash';

interface MelonCashModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const MelonCashModal = (props: MelonCashModalProps) => {
  const { isOpen, setIsOpen } = props;

  const [melonTitle, setMelonTitle] = useState('');

  const { mutate: melonCashMutate } = useMutation(requestFetchMelonCashList, {
    onSuccess: (data, variables) => {
      let parsingData: any;
      data.results.map((x: any, i: number) => {
        const tag = x.properties?.tag?.rich_text?.[0]?.text?.content;
        const url = x.properties?.url?.rich_text?.[0]?.text?.content;

        if (variables === 'title' && tag === 'title') {
          return setMelonTitle(url);
        }
        if (variables === tag) {
          parsingData = {
            type: tag,
            url,
          };
        }
      });

      if (parsingData) {
        if (variables === 'cash') {
          window.location.href = parsingData?.url || '';
        } else {
          return window.open(parsingData?.url);
        }
      }
    },
    onError: (e) => {
      console.log('eee', e);
    },
  });

  const onSelect = (type: string) => {
    melonCashMutate(type);
  };

  useEffect(() => {
    melonCashMutate('title');
  }, []);

  return (
    <Modal
      title={
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={require('@/assets/images/musics/melon.png')} alt="melonicon" style={{ width: 30, height: 30 }} />
            <div style={{ marginLeft: 5 }}>멜론 캐시충전/아이폰 다운로드</div>
          </div>
          <div style={{ fontSize: 14, marginTop: 5, color: '#874cff' }}>멜론 로그인 후 다운로드를 진행해 주세요!</div>
        </div>
      }
      centered
      open={isOpen}
      footer={null}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '50px',
          flexDirection: 'column',
        }}
      >
        <div>{melonTitle}</div>
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: 20 }}>
          {MobileHomeMelonCashData.map((x, i) => (
            <Button
              onClick={() => onSelect(x.type)}
              key={i}
              size={'large'}
              style={{
                width: '80px',
                borderRadius: '15px',
                color: '#282828',
                fontSize: '14px',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {x.name}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default MelonCashModal;
