import { Button, Modal } from 'antd';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import useDeviceType from '@/utils/hooks/useDeviceType';
import { useMutation } from '@tanstack/react-query';
import { requestFetchOneClickMelon, requestFetchOneClickVibe } from '@/api/oneClick';

interface VibeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const VibeModal = (props: VibeModalProps) => {
  const { isOpen, setIsOpen } = props;
  const deviceType = useDeviceType();
  const deviceName = useMemo(() => {
    if (deviceType !== 'ios' && deviceType !== 'android') {
      return `PC(${deviceType})`;
    } else {
      return deviceType;
    }
  }, [deviceType]);

  const { mutate: oneClickMutate } = useMutation(requestFetchOneClickVibe, {
    onSuccess: (data, variables) => {
      let songString = '';
      data.results.map((x: any, i: number) => {
        const songId = x.properties?.songId?.number;
        if (i === 0) {
          songString += songId;
        } else {
          songString += `,${songId}`;
        }
      });

      let url = '';
      url += 'vibe://listen/?trackIds=';

      window.location.href = url + songString;
    },
    onError: (e) => {
      console.log('eee', e);
    },
  });

  const onSelect = (id: number) => {
    oneClickMutate(id);
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={require('@/assets/images/musics/vibe.png')} alt="vibeIcon" style={{ width: 30, height: 30 }} />
            <div style={{ marginLeft: 5 }}>바이브 원클릭 스트리밍</div>
          </div>
          <div style={{ fontSize: 14, marginTop: 5, color: '#874cff' }}>순서대로(또는 역순으로) 버튼 모두 클릭</div>
        </div>
      }
      centered
      open={isOpen}
      footer={null}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '50px',
          flexDirection: 'column',
        }}
      >
        <div>{deviceName}</div>
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: 20 }}>
          {[1, 2, 3, 4].map((x, i) => (
            <Button
              onClick={() => onSelect(x)}
              key={x.toString()}
              size={'large'}
              style={{
                borderRadius: '15px',
                color: '#282828',
                fontSize: '14px',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {`원클릭 ${x}`}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default VibeModal;
