import React from 'react';
import { Layout, Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
// @ts-ignore
import AdSense from 'react-adsense';

const Footer = () => {
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  const onNavigate = (type: string) => {
    if (type === 'weverse') {
      return window.open('https://weverse.io/bts/artistpedia');
    }
    if (type === 'youtube') {
      return window.open('https://youtube.com/@BTS');
    }
    if (type === 'insta') {
      return window.open('https://instagram.com/bts.bighitofficial');
    }
    if (type === 'x') {
      return window.open('https://x.com/bts_bighit');
    }
    if (type === 'tiktok') {
      return window.open('https://tiktok.com/@bts_official_bighit');
    }
    if (type === 'facebook') {
      return window.open('https://facebook.com/bangtan.official');
    }
    if (type === 'daum') {
      return window.open('https://cafe.daum.net/BANGTAN');
    }
    if (type === 'blog') {
      return window.open('https://btsblog.ibighit.com');
    }
  };

  return (
    <Layout.Footer style={{ textAlign: 'center', background: 'transparent' }}>
      <Row justify="center" gutter={[8, 8]}>
        <Col onClick={() => onNavigate('weverse')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/weverse.png')}
            alt={'weverse'}
          />
        </Col>
        <Col onClick={() => onNavigate('youtube')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/youtube.png')}
            alt={'youtube'}
          />
        </Col>
        <Col onClick={() => onNavigate('insta')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/insta.png')}
            alt={'insta'}
          />
        </Col>
        <Col onClick={() => onNavigate('x')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/x.png')}
            alt={'x'}
          />
        </Col>
        <Col onClick={() => onNavigate('tiktok')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/tiktok.png')}
            alt={'tiktok'}
          />
        </Col>
        <Col onClick={() => onNavigate('facebook')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/facebook.png')}
            alt={'facebook'}
          />
        </Col>
        <Col onClick={() => onNavigate('blog')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/blog.png')}
            alt={'blog'}
          />
        </Col>
        <Col onClick={() => onNavigate('daum')}>
          <img
            style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30, cursor: 'pointer' }}
            src={require('@/assets/images/footer/daum.png')}
            alt={'daum'}
          />
        </Col>
      </Row>

      {/* <AdSense.Google
        client="ca-pub-4427135315450396"
        slot="6868785044"
        style={{ display: 'block' }}
        format="auto"
        responsive={'true'}
        adTest={'on'}
      /> */}
      <div
        style={{ color: '#282828', fontSize: isMobile ? 12 : 16, marginTop: 25, cursor: 'pointer' }}
        onClick={() => window.open('https://x.com/ARMY_BADA')}
      >
        방탄소년단 음원서포트팀 @ARMY_BADA
      </div>
      <div style={{ color: '#282828', fontSize: isMobile ? 12 : 16, marginTop: 10 }}>
        Copyright © 2024 아미바다 All rights reserved
      </div>
    </Layout.Footer>
  );
};
export default Footer;
