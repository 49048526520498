import { request } from '@/utils/networks';
import RequestError from '@/utils/networks/RequestError';
import axios from 'axios';

export const requestFetchOneClickMelonShare = async ({}: any) => {
  try {
    const response = await axios.post('/api/notion-oneclick/share');

    return response.data;
  } catch (e) {
    throw new RequestError(e);
  }
};

export const requestFetchOneClickMelon = async (idx: number) => {
  try {
    const response = await axios.post(`/api/notion-oneclick/melon/${idx}`);

    return response.data;
  } catch (e) {
    throw new RequestError(e);
  }
};

export const requestFetchOneClickVibe = async (idx: number) => {
  try {
    const response = await axios.post(`/api/notion-oneclick/vibe/${idx}`);

    return response.data;
  } catch (e) {
    throw new RequestError(e);
  }
};
