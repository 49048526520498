import React, { useEffect, useState } from 'react';
import Header from '@/header';
import Footer from '@/footer';
import { Layout, List } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { NotionRenderer } from 'react-notion';

import 'react-notion/src/styles.css';
// import 'prismjs/themes/prism-tomorrow.css';
import '@/styles/notion.css';

const StreamingList = () => {
  const navigate = useNavigate();
  const [response, setResponse] = useState<any>({});
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  useEffect(() => {
    onSelect('d35d05116ec549d283caacf29eba8a30');
  }, []);

  const onSelect = async (notionId: string) => {
    const response = await axios.get(`https://notion-api.splitbee.io/v1/page/${notionId}`, {
      headers: {
        Pragma: 'no-cache',
      },
    });
    if (!Object.keys(response.data).length) {
      navigate('/'); // 홈으로 리다이렉트
    } else {
      setResponse(response.data);
    }
  };

  return (
    <Layout className={'container'}>
      <Header isNotice={false} />
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginTop: isMobile ? 20 : 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <div style={{ fontWeight: '700', color: '#000', fontSize: isMobile ? 14 : 20 }}>음원 스트리밍 리스트</div>
          <img
            src={require('@/assets/images/guide/streamingList.png')}
            alt="streamingList"
            style={{
              marginTop: 20,
              objectFit: 'contain',
              width: isMobile ? '90%' : '500px',
              height: isMobile ? 'auto' : '80px',
            }}
          />
        </div>
        {Object.keys(response).length ? (
          <div style={{ width: '100%' }}>
            <NotionRenderer hideHeader={true} blockMap={response} fullPage={true} />
          </div>
        ) : null}
      </div>
      <Footer />
    </Layout>
  );
};
export default StreamingList;
