import React, { useState } from 'react';

import { Col, Layout, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { requestFetchNoticeTitle } from '@/api/notice';
import { useMediaQuery } from 'react-responsive';
import { MobileHomeFolderData } from '@/components/home/data';
import MelonModal from '@/components/one-click/melonModal';
import MelonCashModal from '@/components/home/melonCashModal';

const Content = () => {
  const navigate = useNavigate();
  const [isMelonCashModal, setIsMelonCashModal] = useState(false);
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  if (isMobile) {
    return (
      <>
        <Layout.Content style={{ padding: '20px', minHeight: '80vh', background: 'transparent', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                alt={'ready'}
                src={require('@/assets/images/home/mobileReady.gif')}
                style={{
                  width: '250px',
                  height: '350px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  borderRadius: 15,
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => navigate('/one-click')}
              />
              {/*<video*/}
              {/*  src={require('@/assets/images/home/mobileReady.mp4')}*/}
              {/*  muted={true}*/}
              {/*  autoPlay={true}*/}
              {/*  style={{*/}
              {/*    width: '250px',*/}
              {/*    height: '350px',*/}
              {/*    objectFit: 'cover',*/}
              {/*    cursor: 'pointer',*/}
              {/*    borderRadius: 15,*/}
              {/*    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',*/}
              {/*  }}*/}
              {/*  onClick={() => navigate('/one-click')}*/}
              {/*/>*/}
            </div>
            <div
              style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
            >
              <img
                src={require('@/assets/images/home/mobileBt.png')}
                alt="bt"
                style={{
                  width: '90%',
                  height: 'auto',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/de-day')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 0,
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {MobileHomeFolderData.map((x) => {
                return (
                  <div
                    key={x.idx.toString()}
                    style={{
                      width: '40%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      marginTop: 15,
                    }}
                    onClick={() => (x.type === 'navigation' ? navigate(x.navigate) : setIsMelonCashModal(true))}
                  >
                    <img
                      src={x.img}
                      alt={x.idx.toString()}
                      style={{ objectFit: 'contain', width: '70%', cursor: 'pointer' }}
                    />
                  </div>
                );
              })}
              {/* <div
              style={{
                width: '40%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginTop: 15,
              }}
              onClick={() => {
                window.location.href =
                  'melonapp://webview?type=CA&close=N&title=%EC%BA%90%EC%8B%9C%20%EC%B6%A9%EC%A0%84&cashType=&url=https://m2.melon.com/buy/meloncash/charge.htm';
              }}
            >
              <img
                src={require('@/assets/images/home/melonCash.png')}
                alt={'meloncash'}
                style={{ objectFit: 'contain', width: '70%', cursor: 'pointer' }}
              />
            </div>
            <div style={{ width: '40%' }}></div> */}
            </div>
          </div>
        </Layout.Content>
        <MelonCashModal isOpen={isMelonCashModal} setIsOpen={setIsMelonCashModal} />
      </>
    );
  }
  return (
    <Layout.Content style={{ padding: '20px', minHeight: '80vh', background: 'transparent', height: '100%' }}>
      <Row gutter={[16, 16]} align="top" style={{ height: '100%' }}>
        <Col span={4}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '80vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '600px',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ textAlign: 'center', marginBottom: '40px' }} onClick={() => navigate('/streaming-list')}>
                <img
                  src={require('@/assets/images/home/streamingList.png')}
                  alt="right2"
                  style={{ objectFit: 'contain', width: '130px', cursor: 'pointer' }}
                />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '40px' }} onClick={() => navigate('/streaming-guide')}>
                <img
                  src={require('@/assets/images/home/streamingGuide.png')}
                  alt="left1"
                  style={{ objectFit: 'contain', width: '130px', cursor: 'pointer' }}
                />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '40px' }} onClick={() => navigate('/download-guide')}>
                <img
                  src={require('@/assets/images/home/downloadGuide.png')}
                  alt="left2"
                  style={{ objectFit: 'contain', width: '130px', cursor: 'pointer' }}
                />
              </div>
            </div>
          </div>
        </Col>

        <Col span={16}>
          <div
            style={{
              width: '100%',
              height: '80vh',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '530px',
                width: '750px',
              }}
            >
              <img
                src={require('@/assets/images/home/bt.png')}
                alt="bt"
                style={{
                  objectFit: 'contain',
                  position: 'absolute',
                  top: 56,
                  left: 115,
                  width: '300px', // 원하는 크기
                  height: '60px', // 원하는 크기
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/de-day')}
              />
              <img
                src={require('@/assets/images/home/main.png')}
                alt="main"
                style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain' }}
                onClick={() => navigate('/one-click')}
              />
              <img
                src={require('@/assets/images/home/mouse.png')}
                alt="Play Button"
                style={{
                  position: 'absolute',
                  bottom: -10,
                  right: 238,
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/one-click')}
              />
            </div>
          </div>
        </Col>

        <Col span={4}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '80vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '600px',
                justifyContent: 'flex-end',
                position: 'relative',
              }}
            >
              <div style={{ position: 'absolute', top: 0 }} onClick={() => navigate('/vote')}>
                <img
                  src={require('@/assets/images/home/vote.png')}
                  alt="right1"
                  style={{ objectFit: 'contain', width: '130px', cursor: 'pointer' }}
                />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '40px' }} onClick={() => navigate('/fan-chant')}>
                <img
                  src={require('@/assets/images/home/fanChant.png')}
                  alt="right2"
                  style={{ objectFit: 'contain', width: '130px', cursor: 'pointer' }}
                />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '30px' }} onClick={() => navigate('/b-kive')}>
                <img
                  src={require('@/assets/images/home/bKive.png')}
                  alt="right3"
                  style={{ objectFit: 'contain', width: '130px', cursor: 'pointer' }}
                />
              </div>
              <div style={{ textAlign: 'center', marginBottom: '30px' }} onClick={() => navigate('/mv-counting')}>
                <img
                  src={require('@/assets/images/home/mvCounting.png')}
                  alt="right3"
                  style={{ objectFit: 'contain', width: '130px', cursor: 'pointer' }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout.Content>
  );
};
export default Content;
