import { request } from '@/utils/networks';
import RequestError from '@/utils/networks/RequestError';
import axios from 'axios';

export const requestFetchBKiveList = async () => {
  try {
    const response = await axios.post('/api/notion-b-kive');
    return response.data;
  } catch (e) {
    throw new RequestError(e);
  }
};
