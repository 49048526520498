export const MobileHomeFolderData = [
  {
    idx: 0,
    type: 'navigation',
    img: require('@/assets/images/home/m_streamingList.png'),
    navigate: '/streaming-list',
  },
  {
    idx: 1,
    type: 'navigation',
    img: require('@/assets/images/home/m_vote.png'),
    navigate: '/vote',
  },
  {
    idx: 2,
    type: 'navigation',
    img: require('@/assets/images/home/m_streamingGuide.png'),
    navigate: '/streaming-guide',
  },
  {
    idx: 3,
    type: 'navigation',
    img: require('@/assets/images/home/m_fanChant.png'),
    navigate: '/fan-chant',
  },
  {
    idx: 4,
    type: 'navigation',
    img: require('@/assets/images/home/m_downloadGuide.png'),
    navigate: '/download-guide',
  },
  {
    idx: 5,
    type: 'navigation',
    img: require('@/assets/images/home/m_bKive.png'),
    navigate: '/b-kive',
  },
  {
    idx: 6,
    type: 'modal',
    img: require('@/assets/images/home/m_melonCash.png'),
    navigate:
      'melonapp://webview?type=CA&close=N&title=%EC%BA%90%EC%8B%9C%20%EC%B6%A9%EC%A0%84&cashType=&url=https://m2.melon.com/buy/meloncash/charge.htm',
  },
  {
    idx: 7,
    type: 'navigation',
    img: require('@/assets/images/home/m_mvCounting.png'),
    navigate: '/mv-counting',
  },
];

export const MobileHomeMelonCashData = [
  {
    idx: 0,
    type: 'cash',
    name: '캐시충전',
  },
  {
    idx: 1,
    type: 'solo',
    name: '개별곡',
  },
  {
    idx: 2,
    type: '16bit',
    name: '16bit',
  },
  {
    idx: 3,
    type: '24bit',
    name: '24bit',
  },
];
