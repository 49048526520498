import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import '@/styles/header.css';
import { useQuery } from '@tanstack/react-query';
import { requestFetchNoticeTitle } from '@/api/notice';
import { useMediaQuery } from 'react-responsive';

interface HeaderProps {
  isNotice: boolean;
}

const Header = (props: HeaderProps) => {
  const { isNotice } = props;
  const navigate = useNavigate(); // 리다이렉트를 위한 훅
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });
  const { data: noticeTitleData = [] } = useQuery(['requestFetchNoticeTitle'], () => requestFetchNoticeTitle(), {
    select: (data) => {
      return data?.results?.[0]?.properties?.content.rich_text?.[0].text.content || '-';
    },
    retry: false,
    enabled: isNotice,
  });

  return (
    <Layout.Header
      style={{
        display: 'flex',
        padding: 0,
        zIndex: 999,
        left: 0,
        top: 0,
        width: '100%',
        position: 'fixed',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fff',
          padding: isMobile ? 10 : '0 50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          <img
            style={{ width: isMobile ? 40 : 50, height: 'auto', objectFit: 'contain' }}
            src={require('@/assets/images/header/header_logo.png')}
            alt={'header_logo'}
          />
          <img
            style={{
              width: isMobile ? 150 : 230,
              height: isMobile ? 'auto' : 35,
              objectFit: 'contain',
              marginLeft: 10,
            }}
            src={require('@/assets/images/header/header_text.png')}
            alt={'header_text'}
          />
        </div>

        {isNotice ? (
          <div
            style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => navigate('/notice')}
          >
            <img
              style={{ width: isMobile ? 25 : 30, height: 'auto', objectFit: 'contain' }}
              src={require('@/assets/images/header/notice.png')}
              alt={'notice'}
            />
            {isMobile ? null : (
              <div style={{ marginLeft: 10, fontSize: 18, fontWeight: 'bold' }}>{noticeTitleData}</div>
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
    </Layout.Header>
  );
};

export default Header;
