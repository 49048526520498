export const PausingGuideData = [
  {
    id: 'sound',
    select: true,
    name: '사운드어시스턴트',
    icon: require('@/assets/images/guide/soundAssistant.png'),
    notionId: '8fcee8bebf574f0e8e2fc6db50fd53e5',
  },
  {
    id: 'automate',
    select: false,
    name: '오토메이트',
    icon: require('@/assets/images/guide/automate.png'),
    notionId: '0a34ddc0730e4826a10e81df74dc2c39',
  },
  {
    id: 'ios',
    select: false,
    name: '단축어',
    icon: require('@/assets/images/guide/ios.png'),
    notionId: '7416b45be809496684aef6b6da1cc57b',
  },
];
