export const StreamingGuideData = [
  {
    id: 'melon',
    select: true,
    name: '멜론',
    icon: require('@/assets/images/musics/melon.png'),
    notionId: 'e947ce4120a94468bd4bc890847d3835',
  },
  {
    id: 'genie',
    select: false,
    name: '지니',
    icon: require('@/assets/images/musics/genie.png'),
    notionId: '7f5cdb732cc44f99b7cc9efcb596e500',
  },
  {
    id: 'flo',
    select: false,
    name: '플로',
    icon: require('@/assets/images/musics/flo.png'),
    notionId: '08c0457032ea4ddcbe097b6bfe649353',
  },
  {
    id: 'vibe',
    select: false,
    name: '바이브',
    icon: require('@/assets/images/musics/vibe.png'),
    notionId: 'c6d4f998349140c2bc24b3d108b72040',
  },
  {
    id: 'bugs',
    select: false,
    name: '벅스',
    icon: require('@/assets/images/musics/bugs.png'),
    notionId: '0650a20ed5e7476c92302a1a26e944e7',
  },
  {
    id: 'spotify',
    select: false,
    name: '스포티파이',
    icon: require('@/assets/images/musics/spotify.png'),
    notionId: '8b30a7aea8f7463080edae5256a8dd01',
  },
  {
    id: 'youtubeMusic',
    select: false,
    name: '유튜브뮤직',
    icon: require('@/assets/images/musics/youtubeMusic.png'),
    notionId: 'ab49c4ec50ee434089c5f7723d4ac237',
  },
  {
    id: 'appleMusic',
    select: false,
    name: '애플뮤직',
    icon: require('@/assets/images/musics/appleMusic.png'),
    notionId: 'a6a426cd14044a20bfad0e3e5b83e233',
  },
  {
    id: 'youtubeMv',
    select: false,
    name: '유튜브MV',
    icon: require('@/assets/images/musics/youtubeMv.png'),
    notionId: '4aba8e57dcfd4746ad9cad6e203e7baa',
  },
  {
    id: 'sound',
    select: true,
    name: '사운드어시스턴트',
    icon: require('@/assets/images/guide/soundAssistant.png'),
    notionId: '8fcee8bebf574f0e8e2fc6db50fd53e5',
  },
  {
    id: 'automate',
    select: false,
    name: '오토메이트',
    icon: require('@/assets/images/guide/automate.png'),
    notionId: '0a34ddc0730e4826a10e81df74dc2c39',
  },
  {
    id: 'ios',
    select: false,
    name: '단축어',
    icon: require('@/assets/images/guide/ios.png'),
    notionId: '7416b45be809496684aef6b6da1cc57b',
  },
];
