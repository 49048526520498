import { useState, useEffect } from 'react';

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    // 모바일 환경 체크
    if (/iphone|ipad|ipod/i.test(userAgent)) {
      setDeviceType('ios');
    } else if (/android/i.test(userAgent)) {
      setDeviceType('android');
    } else if (/windows/i.test(userAgent)) {
      setDeviceType('windows');
    } else if (/macintosh|mac os x/i.test(userAgent)) {
      setDeviceType('mac');
    }
  }, []);

  return deviceType;
};

export default useDeviceType;
