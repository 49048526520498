import { get } from 'lodash';
import { message } from 'antd';

class RequestError extends Error {
  readonly statusCode?: number;

  readonly code?: string;

  readonly status?: number;

  readonly result?: boolean;

  readonly detailMessage?: string;

  readonly error?: unknown;

  constructor(e: unknown) {
    const msg = String(get(e, 'response.data.message')) ?? '알림';
    super(msg);
    this.statusCode = get(e, 'response.data.code');
    this.status = get(e, 'response.status');
    this.result = get(e, 'response.data.success') ?? false;
    this.code = get(e, 'code');
    this.error = get(e, 'response.data');

    // if (this.status === 401) {
    //   RequestError.handle401Error();
    // }

    // Error Alert
    if (message) {
      message.error(msg);
    }

    // if (this.status === 403) {
    //   RequestError.handle403Logout();
    // }
  }

  // private static async handle401Error() {
  //   try {
  //     if (typeof window !== 'undefined') {
  //       useUserStore.getState().clear();
  //       window.location.replace('/signin');
  //     }
  //   } catch (error) {
  //     RequestError.handle403Logout();
  //   }
  // }
  //
  // private static handle403Logout() {}
}

export default RequestError;
