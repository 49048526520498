import Header from '@/header';
import Footer from '@/footer';
import React, { useEffect, useState } from 'react';
import { Layout, List, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { NotionRenderer } from 'react-notion';
import { requestFetchFanChantList } from '@/api/fanChant';
import './style.css';
import 'react-notion/src/styles.css';
// import 'prismjs/themes/prism-tomorrow.css';
import '@/styles/notion.css';
import { useMediaQuery } from 'react-responsive';

const FanChant = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });
  const [response, setResponse] = useState({});

  const { data: fanChantData = [] } = useQuery(['requestFetchFanChantList'], () => requestFetchFanChantList(), {
    select: (data) => {
      const temp: any[] = [];
      data?.results?.map((x: any) => {
        const tag = x.properties?.tag?.rich_text?.[0]?.text?.content;
        const sorting = x.properties?.sorting?.number;
        temp.push({
          value: x?.id,
          label: tag,
          sorting,
        });
      });
      temp.sort((a, b) => a.sorting - b.sorting);
      return temp;
    },
    retry: false,
  });

  useEffect(() => {
    if (fanChantData.length > 0 && !Object.keys(response).length) {
      onSelect(fanChantData?.[0]?.value);
    }
  }, [fanChantData, response]);

  const onSelect = async (notionId: string) => {
    const response = await axios.get(`https://notion-api.splitbee.io/v1/page/${notionId}`, {
      headers: {
        Pragma: 'no-cache',
      },
    });
    if (!Object.keys(response.data).length) {
      navigate('/'); // 홈으로 리다이렉트
    } else {
      setResponse(response.data);
    }
  };

  const handleChange = async (value: string) => {
    console.log(`selected ${value}`);
    await onSelect(value);
  };

  return (
    <Layout className={'container'}>
      <Header isNotice={false} />
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginTop: isMobile ? 20 : 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <div style={{ fontWeight: '700', color: '#000', fontSize: isMobile ? 14 : 20 }}>응원법</div>
          <img
            src={require('@/assets/images/guide/fanChant.png')}
            alt="fanChant"
            style={{
              marginTop: 20,
              objectFit: 'contain',
              width: isMobile ? '67%' : '370px',
              height: isMobile ? 'auto' : '55px',
            }}
          />
          <img
            src={require('@/assets/images/guide/fanChant_content.png')}
            alt="fanChant_content"
            style={{
              marginTop: 20,
              objectFit: 'contain',
              width: isMobile ? '90%' : '300px',
              height: isMobile ? 'auto' : '40px',
            }}
          />
        </div>

        {fanChantData.length > 0 && (
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            defaultValue={fanChantData?.[0]?.label}
            loading={!(fanChantData.length > 0)}
            style={{
              width: 300,
              marginTop: 20,
            }}
            onChange={handleChange}
            options={fanChantData}
            className={'custom-select'}
          />
        )}

        {Object.keys(response).length ? (
          <div style={{ width: '100%' }}>
            <NotionRenderer hideHeader={true} blockMap={response} fullPage={true} />
          </div>
        ) : null}
      </div>
      <Footer />
    </Layout>
  );
};
export default FanChant;
