import { request } from '@/utils/networks';
import RequestError from '@/utils/networks/RequestError';
import axios from 'axios';

export const requestFetchMelonCashList = async (type: string) => {
  try {
    const response = await axios.post('/api/notion-melon/cash');
    return response.data;
  } catch (e) {
    throw new RequestError(e);
  }
};
