import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { NotionRenderer } from 'react-notion';
import Header from '@/header';
import { Layout } from 'antd';
import Footer from '@/footer';

import 'react-notion/src/styles.css';
// import 'prismjs/themes/prism-tomorrow.css';
import '@/styles/notion.css';

const NoticeDetail = () => {
  const id = useParams<{ id: string }>().id;
  const navigate = useNavigate(); // 리다이렉트를 위한 훅
  const [response, setResponse] = useState({});

  useEffect(() => {
    const callNotion = async () => {
      const response = await axios.get(`https://notion-api.splitbee.io/v1/page/${id}`, {
        headers: {
          Pragma: 'no-cache',
        },
      });
      if (!Object.keys(response.data).length) {
        navigate('/notice'); // 홈으로 리다이렉트
      } else {
        setResponse(response.data);
      }
    };
    if (!id) {
      navigate('/'); // 홈으로 리다이렉트
    } else {
      callNotion();
    }
  }, [id, navigate]);

  return Object.keys(response).length ? (
    <Layout className={'container'}>
      <Header isNotice={false} />
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <NotionRenderer hideHeader={true} blockMap={response} fullPage={true} />
      </div>
      <Footer />
    </Layout>
  ) : null;
};
export default NoticeDetail;
