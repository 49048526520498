import Header from '@/header';
import Footer from '@/footer';
import React from 'react';
import { Layout, List } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { requestFetchNoticeList } from '@/api/notice';
import { useMediaQuery } from 'react-responsive';

import 'react-notion/src/styles.css';
// import 'prismjs/themes/prism-tomorrow.css';
import '@/styles/notion.css';

const Notice = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });
  const { data: noticeData = [] } = useQuery(['requestFetchNoticeList'], () => requestFetchNoticeList(), {
    select: (data) => {
      const temp: any[] = [];
      data?.results?.map((x: any) => {
        const useYn = x.properties?.useYN?.select?.name;
        if (useYn === 'Y') {
          const content = x.properties?.content?.rich_text?.[0]?.text?.content;
          const title = x.properties?.title?.title?.[0]?.text?.content;
          const titleYn = x.properties?.titleYN?.select?.name;
          const sorting = x.properties?.sorting?.number;
          const create = new Date(x.properties?.create?.created_time);
          temp.push({
            title,
            id: x?.id,
            content,
            titleYn,
            sorting,
            create: `${create.getFullYear()}-${create.getMonth() + 1}-${create.getDate()}`,
          });
        }
      });
      temp.sort((a, b) => a.sorting - b.sorting);
      return temp;
    },
    retry: false,
  });

  const onNoticeDetail = async (id: string) => {
    if (!id) {
      console.log('잘못됨');
    } else {
      navigate(`/notice/${id}`);
    }
  };

  return (
    <Layout className={'container'}>
      <Header isNotice={false} />
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginTop: isMobile ? 20 : 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <div style={{ fontWeight: '700', color: '#000', fontSize: isMobile ? 14 : 20 }}>공지사항</div>
          <img
            src={require('@/assets/images/guide/notice.png')}
            alt="notice"
            style={{
              marginTop: 20,
              objectFit: 'contain',
              width: isMobile ? '70%' : '230px',
              height: isMobile ? 'auto' : '80px',
            }}
          />
        </div>

        <List
          style={{ width: '60%', borderColor: '#fff', marginTop: 60, borderRadius: 0 }}
          size="large"
          bordered
          loading={noticeData.length === 0}
          dataSource={noticeData}
          renderItem={(item) => (
            <List.Item
              onClick={() => onNoticeDetail(item?.id)}
              style={{
                borderColor: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: 18, fontWeight: '600' }}>{item.title}</div>
                <div>{item.content}</div>
              </div>
              <div>{item.create}</div>
            </List.Item>
          )}
        />
      </div>
      <Footer />
    </Layout>
  );
};
export default Notice;
