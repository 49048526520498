import Header from '@/header';
import Footer from '@/footer';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Layout, List } from 'antd';
import { useNavigate } from 'react-router-dom';

import './style.css';
import MelonModal from '@/components/one-click/melonModal';
import { useMutation } from '@tanstack/react-query';
import { requestFetchOneClickMelonShare } from '@/api/oneClick';
import MobileModal from '@/components/one-click/mobileModal';
import useDeviceType from '@/utils/hooks/useDeviceType';
import { OneClickData } from '@/pages/one-click/data';
import { useMediaQuery } from 'react-responsive';
import VibeModal from '@/components/one-click/vibeModal'; 

const OneClick = () => {
  const navigate = useNavigate();
  const [isMelonModalOpen, setIsMelonModalOpen] = useState(false);
  const [isVibeModalOpen, setIsVibeModalOpen] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [updateName, setUpdateName] = useState('')
  const [mobileModalType, setMobileModalType] = useState('');
  const deviceType = useDeviceType();
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  const { mutate: shareMutate } = useMutation(requestFetchOneClickMelonShare, {
    onSuccess: (data, variables) => {
      data.results.map((x: any) => {
        if (x.properties?.tag?.rich_text?.[0]?.text?.content === variables) {
          const url = x?.properties?.shareUrl?.rich_text?.[0]?.text?.content;
          if (variables.includes('ios')) {
            window.location.href = url;
            return;
          } else if (variables.includes('aos')) {
            window.location.href = url;
            return;
          } else if (variables.includes('flo')) {
            window.location.href = url;
            return;
          } else if(variables.includes('updateDate')){
            setUpdateName(url)
            return;
          } 
          else {
            setTimeout(() => {
              return window.open(url, '_blank');
            }, 500);
          }
        }
      });
    },
    onError: (e) => {
      console.log('eee', e);
    },
  });

  const chooseStepButton = (index: number) => {
    if (index === 1) {
      if (deviceType === 'ios') {
        shareMutate('bugs_ios');
      } else if (deviceType === 'android') {
        shareMutate('bugs_aos');
      } else {
        shareMutate('bugs');
      }
    }
    if (index === 2) {
      if (deviceType === 'ios') {
        shareMutate('genie_ios');
      } else if (deviceType === 'android') {
        shareMutate('genie_aos');
      } else {
        shareMutate('genie');
      }
    }
    if (index === 3) {
      console.log('melon');
      setIsMelonModalOpen(true);
    }
    if (index === 4) {
      if (deviceType !== 'ios' && deviceType !== 'android') {
        setMobileModalType('flo');
        setIsMobileModalOpen(true);
      } else {
        shareMutate('flo');
      }
    }
    if (index === 5) {
      if (deviceType !== 'ios' && deviceType !== 'android') {
        setMobileModalType('vibe');
        setIsMobileModalOpen(true);
      } else {
        // shareMutate('vibe');
        setIsVibeModalOpen(true);   
      }
    }
    if (index === 6) {
      shareMutate('spotify');
    }
    if (index === 7) {
      shareMutate('youtube');
    }
    if (index === 8) {
      // shareMutate('apple');
      alert('시스템 준비중입니다.')
    }
  };


  useEffect(()=>{
    shareMutate('updateDate')
  },[]);

  return (
    <>
      <Layout className={'container'}>
        <Header isNotice={false} />
        <div
          style={{
            width: '100%',
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginTop: isMobile ? 20 : 60,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0 20px',
            }}
          >
            <div style={{ fontWeight: 'bold', color: '#000', fontSize: isMobile ? 14 : 20 }}>원클릭 스트리밍</div>
            <img
              src={require('@/assets/images/guide/oneClick.png')}
              alt="oneClick"
              style={{
                marginTop: 20,
                objectFit: 'contain',
                width: isMobile ? '70%' : '500px',
                height: isMobile ? 'auto' : '50px',
              }}  
            />
            <img
              src={require('@/assets/images/guide/oneClick_content.png')}
              alt="oneClick_content"
              style={{
                marginTop: isMobile ? 30 : 40,
                objectFit: 'contain',
                width: isMobile ? '100%' : '400px',
                height: isMobile ? 'auto' : '40px',
              }}
            />
          </div>
          <div style={{marginTop:isMobile ? 20 : 120}}>
              <div style={{background:'#BDB4FD', paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,borderRadius:30}}>
                <div style={{  color: '#fff', fontSize: isMobile ? 14 : 20 }}>{updateName}</div>
              </div>
            </div> 
          <div
            style={{
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', flexWrap: 'wrap', marginTop: isMobile ? 30 : 60, justifyContent: 'center' }}
            >

              {OneClickData.map((item) => (
                <img
                  key={item.idx.toString()}
                  className={'imgContainer'}
                  style={{ width: isMobile ? '40%' : '200px', height: isMobile ? 'auto' : '300px' }}
                  src={item.img}
                  alt={item.idx.toString()}
                  onClick={() => chooseStepButton(item.idx)}
                />
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </Layout>
      <MelonModal isOpen={isMelonModalOpen} setIsOpen={setIsMelonModalOpen} />
      <VibeModal isOpen={isVibeModalOpen} setIsOpen={setIsVibeModalOpen} />
      <MobileModal isOpen={isMobileModalOpen} setIsOpen={setIsMobileModalOpen} type={mobileModalType} />

    </>
  );
};
export default OneClick;
