import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import App from '@/App';
import ErrorPage from '@/pages/404';
import Notice from '@/pages/notice';
import NoticeDetail from '@/pages/notice/detail';
import DeDay from '@/pages/de-day';
import StreamingGuide from '@/pages/streaming-guide';
import DownloadGuide from '@/pages/download-guide';
import PausingGuide from '@/pages/pausing-guide';
import FanChant from '@/pages/fan-chant';
import StreamingList from '@/pages/streaming-list';
import OneClick from '@/pages/one-click';
import useRouteChangeTracker from '@/utils/hooks/useRouteChangeTracker';
import Vote from '@/pages/vote';
import BKive from '@/pages/b-kive';
import MvCounting from '@/pages/mv-counting';

const Router = () => {
  return (
    <React.StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/de-day" element={<DeDay />} />
          <Route path="/notice/:id" element={<NoticeDetail />} />
          <Route path="/streaming-guide" element={<StreamingGuide />} />
          <Route path="/download-guide" element={<DownloadGuide />} />
          <Route path="/pausing-guide" element={<PausingGuide />} />
          <Route path="/fan-chant" element={<FanChant />} />
          <Route path="/vote" element={<Vote />} />
          <Route path="/streaming-list" element={<StreamingList />} />
          <Route path="/one-click" element={<OneClick />} />
          <Route path="/b-kive" element={<BKive />} />
          <Route path="/mv-counting" element={<MvCounting />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default Router;
