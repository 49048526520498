import React, { useEffect } from 'react';

import './App.css';
import '@/styles/fonts.css';

import { Layout } from 'antd';
import Header from '@/header';
import Footer from '@/footer';
import Content from '@/content';
import useRouteChangeTracker from '@/utils/hooks/useRouteChangeTracker';

function App() {
  useRouteChangeTracker();

  return (
    <Layout className={'container'}>
      <Header isNotice={true} />
      <Content />
      <Footer />
    </Layout>
  );
}

export default App;
