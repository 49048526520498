import { Modal } from 'antd';
import React, { Dispatch, SetStateAction, useMemo } from 'react';

interface GenieModalProps {
  isOpen: boolean;
  type: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileModal = (props: GenieModalProps) => {
  const { isOpen, setIsOpen, type } = props;
  const typeName = useMemo(() => {
    if (type === 'vibe') {
      return '바이브';
    } else if (type === 'flo') {
      return '플로';
    } else {
      return '';
    }
  }, [type]);

  const typeImageName = useMemo(() => {
    if (type === 'vibe') {
      return require(`@/assets/images/musics/vibe.png`);
    } else if (type === 'flo') {
      return require(`@/assets/images/musics/flo.png`);
    } else {
      return '';
    }
  }, [type]);

  return (
    <Modal
      title={
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={typeImageName} alt="vibe" style={{ width: 30, height: 30 }} />
            <div style={{ marginLeft: 5 }}>{`${typeName} 원클릭 스트리밍`}</div>
          </div>
        </div>
      }
      centered
      open={isOpen}
      footer={null}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '50px' }}>
        <div style={{ fontSize: 18, fontWeight: 'bold' }}>{`${typeName} 원클릭은 모바일 환경에서만 지원됩니다.`}</div>
      </div>
    </Modal>
  );
};

export default MobileModal;
