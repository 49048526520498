import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let styleElem = document.body.appendChild(document.createElement('style'));
    styleElem.innerHTML = ':before { content:none!important} :after { content:none!important}';

    return () => {
      styleElem.innerHTML = '';
    };
  }, []);

  return (
    <div className="box-error">
      <div className="inner-error">
        <div className="in">
          <p className="txt-1">404</p>
          <p className="txt-2">원하시는 페이지를 찾을 수가 없습니다.</p>
          <p className="txt-3">
            방문 원하시는 페이지의 주소가 잘못 입력되었거나, <br />
            변경 혹은 삭제되어 요청하신 페이지를 찾을 수가 없습니다.
          </p>
          <Button onClick={() => navigate('/', { replace: true })}>홈으로 돌아가기</Button>
        </div>
      </div>
    </div>
  );
};
export default ErrorPage;
