import React from 'react';
import { render } from 'react-dom';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import reportWebVitals from './reportWebVitals';

import Router from '@/utils/router';

export const queryClient = new QueryClient();

render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'NanumSquareR',
        },
      }}
    >
      <Router />
    </ConfigProvider>
  </QueryClientProvider>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. more: https://bit.ly/CRA-vitals
reportWebVitals();
