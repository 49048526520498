import Header from '@/header';
import Footer from '@/footer';
import React from 'react';
import { Layout, Row, Col, Progress } from 'antd';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const DeDay = () => {
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  const calculateProgress = (startDate: string, endDate: string): number => {
    const enlistmentDate = moment(startDate, 'YYYY-MM-DD'); // 입대일 설정
    const today = moment(); // 오늘 날짜

    const totalServiceDays = moment(endDate, 'YYYY-MM-DD').diff(enlistmentDate, 'days'); // 총 복무 일수
    const servedDays = today.diff(enlistmentDate, 'days'); // 복무한 일수

    const progress = Math.min((servedDays / totalServiceDays) * 100, 100); // 진행률 계산
    return parseFloat(progress.toFixed(1)); // 소수점 한 자리까지 표시
  };

  const data = [
    { name: 'rm', img: require('@/assets/images/deDay/rm.png'), date: '2025.06.10', startDate: '2023-12-11' },
    { name: 'jin', img: require('@/assets/images/deDay/jin.png'), date: '2024.06.12', startDate: '2022-12-13' },
    { name: 'suga', img: require('@/assets/images/deDay/suga.png'), date: '2025.06.21', startDate: '2023-09-22' },
    { name: 'jhope', img: require('@/assets/images/deDay/jhope.png'), date: '2024.10.17', startDate: '2023-04-18' },
    { name: 'jimin', img: require('@/assets/images/deDay/jimin.png'), date: '2025.06.11', startDate: '2023-12-12' },
    { name: 'v', img: require('@/assets/images/deDay/v.png'), date: '2025.06.10', startDate: '2023-12-11' },
    {
      name: 'jungkook',
      img: require('@/assets/images/deDay/jungkook.png'),
      date: '2025.06.11',
      startDate: '2023-12-12',
    },
  ];

  const updatedData = data.map((item) => ({
    ...item,
    progress: calculateProgress(item.startDate, item.date),
  }));

  return (
    <Layout className={'container'}>
      <Header isNotice={false} />
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginTop: isMobile ? 20 : 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <div style={{ fontWeight: '700', color: '#000', fontSize: isMobile ? 14 : 20 }}>2025년 방탄 기달력🖤</div>
          <img
            src={require('@/assets/images/guide/deDay.png')}
            alt="deDay"
            style={{
              marginTop: 20,
              objectFit: 'contain',
              width: isMobile ? '100%' : '700px',
              height: isMobile ? 'auto' : '80px',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            // alignItems: 'center',
            padding: '0 20px',
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              // alignItems: 'center',
              justifyContent: 'center',
              // backgroundColor: 'red',
            }}
          >
            {updatedData.map((item, index) => (
              <div
                key={index.toString()}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: index === 0 ? (isMobile ? '20px' : 0) : '20px',
                }}
              >
                <img
                  src={item.img}
                  alt={item.name}
                  style={{ objectFit: 'contain', width: '50px', borderRadius: '50%' }}
                />

                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, width: '300px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ color: '#282828', fontSize: '16px' }}>{item.progress}%</span>
                    <span style={{ color: '#282828', fontSize: '16px' }}>{item.date}</span>
                  </div>

                  <div style={{ width: '100%' }}>
                    <Progress
                      percent={item.progress}
                      strokeColor={item.progress >= 80 ? '#ac92f0' : '#fff'}
                      trailColor="#facdcc"
                      showInfo={false}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};
export default DeDay;
